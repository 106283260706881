const endpoints = {
  GET_PROFILE: "/students/profile",
  GET_STUDENT: "/students",
  UPDATE_CONTACT_INFO: "/students/profile/contact-info",
  CHANGE_PASSWORD: "/students/password/change",
  ONBOARDING: "/students/onboarding",
  CHANGE_EMAIL: "/students/update-email",
  CLOSE_ACCOUNT: "/students/close-account",
  AUTHENTICATE_WITH_GOOGLE: "/auth/login/google",
};

export default endpoints;
