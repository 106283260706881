import axios, { AxiosResponse } from "axios";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SetURLSearchParams } from "react-router-dom";
import { capitalize } from "../HelperFunctions/capitalize";
import requestHandler from "../HelperFunctions/requestHandler";
import { setNotiticationFunction } from "../Utilities/setNotificationsFunction";
import { AppContext } from "./AppContext";
import { backend_url } from "../Utilities/global";
import { setToken, TokenKeys } from "v2/Utilities/token";
import errors from "Utilities/errors";

export type requestType = {
  isLoading: boolean;
  data?: null | any[] | string | any;
  error?: null | any;
};

type AuthUserContextValueType = {
  userLoginInfo: {
    email: string;
    password: string;
    isSubscribed: boolean;
    remember: boolean;
  };
  setUserLoginInfo: Dispatch<
    SetStateAction<{
      email: string;
      password: string;
      isSubscribed: boolean;
      remember: boolean;
    }>
  >;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
  fetchCountries: () => void;
  countriesRequestObject: requestType;
  setCountriesRequestObject: Dispatch<SetStateAction<requestType>>;
  signUp: () => void;
  setSignUpRequest: Dispatch<SetStateAction<requestType>>;
  signupRequest: requestType;
  verifyEmail: (token: string) => void;
  verifyEmailRequest: requestType;
  setVerifyEmailRequest: Dispatch<SetStateAction<requestType>>;
  signInRequest: requestType;
  setSignInRequest: Dispatch<SetStateAction<requestType>>;
  signIn: () => void;
  logout: () => void;
  resetPassword: () => void;
  resetPasswordRequest: requestType;
  setResetPasswordRequest: Dispatch<SetStateAction<requestType>>;
  completeProfileData: completeUserProfile;
  setCompleteProfileData: Dispatch<SetStateAction<completeUserProfile>>;
  completeProfile: () => void;
  gender: string;
  setGender: Dispatch<SetStateAction<string>>;
  country: string;
  setCountry: Dispatch<SetStateAction<string>>;
  employmentStatus: string;
  educationLevel: string;
  setEmploymentStatus: Dispatch<SetStateAction<string>>;
  setEducationLevel: Dispatch<SetStateAction<string>>;
  completeProfileRequest: requestType;
  setCompleteProfileRequest: Dispatch<SetStateAction<requestType>>;
  resendVerifyEmail: () => void;
  resetPasswordObject: {
    newPassword: string;
    confirmNewPassword: string;
  };
  setResetPasswordObject: Dispatch<
    SetStateAction<{ newPassword: string; confirmNewPassword: string }>
  >;
  verifyResetPassword: (token: string) => void;
  contactProgramAdvisorObject: {
    name: string;
    email: string;
    message: string;
    advisor_email: string;
  };
  setContactProgramAdvisorObject: Dispatch<
    SetStateAction<{
      name: string;
      email: string;
      message: string;
      advisor_email: string;
    }>
  >;
  contactProgramAdvisorRequest: requestType;
  setContactProgramAdvisorRequest: Dispatch<SetStateAction<requestType>>;
  eligibilityContactProgramAdvisor: () => void;
  getEligibilityQuestions: (id: string) => void;
  eligibilityQuestions: any;
  setEligibilityQuestions: Dispatch<SetStateAction<any>>;
  getEligibilityQuestionsrequest: requestType;
  setGetElibilityQuestionsRequest: Dispatch<SetStateAction<requestType>>;
  userToken: string | null;
  eligibleAnswers: any;
  setEligibleAnswers: Dispatch<SetStateAction<any>>;
  gradeEligibilityQuestions: (id: string) => void;
  gradeEligibilityQuestionsRequest: requestType;
  setGradeEligibilityQuestionsRequest: Dispatch<SetStateAction<requestType>>;
  getCoursesById: (id: string) => void;
  iseCoursesDetail: requestType;
  user: requestType;
  iseUserFirstName: string | null;
  passwordUpdate: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  };
  setPasswordUpdate: Dispatch<
    SetStateAction<{
      oldPassword: string;
      newPassword: string;
      confirmPassword: string;
    }>
  >;
  updatePasswordHandlerObject: requestType;
  updatePasswordHandler: () => void;
  emailUpdate: { newEmail: string };
  setEmailUpdate: Dispatch<SetStateAction<{ newEmail: string }>>;
  updateEmailHandlerObject: requestType;
  updateEmailHandler: () => void;
  updateSkillsHandler: () => void;
  updateSkillsHandlerObject: requestType;
  skillsUpdate: {
    top_skills: string[];
    employement_status: string;
    education: string;
    experience_level: string;
  };
  setSkillsUpdate: Dispatch<
    SetStateAction<{
      top_skills: string[];
      employement_status: string;
      education: string;
      experience_level: string;
    }>
  >;
  getUser: (load?: boolean) => void;
  updateAboutHandler: () => void;
  updateAboutHandlerObject: requestType;
  aboutInfoUpdate: {
    full_name: string;
    profile_image?: any;
    gender: string;
    country: string;
    preferred_language: string;
  };

  setAboutInfoUpdate: Dispatch<
    SetStateAction<{
      full_name: string;
      profile_image?: any;
      gender: string;
      country: string;
      preferred_language: string;
    }>
  >;
  aboutInfoFormData: FormData;
  updateTopSkillsHandlerObject: requestType;
  updateSTopkillsHandler: () => void;
  topSkillsUpdate: {
    dob: string;
    bio: string;
    phone: string;
    linkedIn_profile: string;
  };
  setTopSkillsUpdate: Dispatch<
    SetStateAction<{
      dob: string;
      bio: string;
      phone: string;
      linkedIn_profile: string;
    }>
  >;
  getUserBadges: () => void;
  userBadgesObject: requestType;
  closeAccount: () => void;
  closeAccountObject: requestType;
  setCloseAccountObject: Dispatch<SetStateAction<requestType>>;
  badges: any[];
  setBadges: Dispatch<SetStateAction<any[]>>;
};

type AuthUserContextProviderTypes = {
  children: React.ReactNode;
};

type completeUserProfile = {
  first_name: string;
  last_name: string;
  gender: string;
  country: string;
  bio: string;
  employement_status: string;
  education: string;
  linkedIn_profile: string;
  profile_image: string;
};

export const AuthUserContext = createContext<AuthUserContextValueType>(
  {} as AuthUserContextValueType
);

const AuthUserContextProvider = ({
  children,
}: AuthUserContextProviderTypes) => {
  // Context
  const { setNotifications } = useContext(AppContext);

  // States
  const [user, setUser] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  const [userLoginInfo, setUserLoginInfo] = useState<{
    email: string;
    password: string;
    isSubscribed: boolean;
    remember: boolean;
  }>({
    email: "",
    password: "",
    isSubscribed: false,
    remember: false,
  });

  const [countriesRequestObject, setCountriesRequestObject] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });

  const [signupRequest, setSignUpRequest] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  const [signInRequest, setSignInRequest] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  const [verifyEmailRequest, setVerifyEmailRequest] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  const [resetPasswordRequest, setResetPasswordRequest] = useState<requestType>(
    {
      isLoading: false,
      data: null,
      error: null,
    }
  );
  const [iseCoursesDetail, setIseCoursesDetail] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  // Query Params
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSearchParams = new URLSearchParams(window.location.search);
  const referralId = searchParams.get("referralId");

  // Routing
  const navigate = useNavigate();
  const location = useLocation();

  // Utils
  const redirectRoute = location.state || "/dashboard";
  const userToken = localStorage.getItem("iseAccessToken");
  const iseUSerEmail = localStorage.getItem("ise-user-email");
  const iseUserFirstName = localStorage.getItem("ise-user-firstname");

  // Requests
  const logout = () => {
    localStorage.removeItem("iseAccessToken");
    localStorage.removeItem("iseRefreshToken");

    navigate("/sign-in", { state: location.pathname });
  };

  const getUser = (load = true) => {
    if (!userToken) {
      return;
    }

    if (!load) {
      setUser((prevState) => {
        return { ...prevState, isLoading: false };
      });
    } else {
      setUser({
        isLoading: true,
        data: null,
        error: null,
      });
    }

    requestHandler({
      url: `${backend_url}/api/ise/v1/students`,
      method: "GET",
    })
      .then((res) => {
        setUser({
          isLoading: false,
          data: (res as any).data,
          error: null,
        });

        // if (!res.data.onboarding_complete) {
        //   return navigate("/onboarding");
        // }
      })
      .catch((err) => {
        const errorMessage = errors.format(err);

        setUser({
          isLoading: false,
          data: null,
          error: errorMessage,
        });

        setNotiticationFunction(setNotifications, errorMessage);

        if (
          errorMessage === "Expired Token" ||
          errorMessage === "Invalid Token"
        ) {
          logout();
        }
      });
  };

  useEffect(() => {
    if (userToken) {
      getUser(true);
    }

    // eslint-disable-next-line
  }, []);

  const fetchCountries = () => {
    if (countriesRequestObject.isLoading) {
      return;
    }
    setCountriesRequestObject({
      isLoading: true,
      data: null,
      error: null,
    });
    axios
      .get(`https://restcountries.com/v3.1/all?fields=name,idd,flag`)

      .then((res) => {
        setCountriesRequestObject({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((err) => {
        setCountriesRequestObject({
          isLoading: false,
          data: null,
          error: err?.response?.data?.message,
        });
      });
  };

  const signUp = () => {
    setSignUpRequest({ isLoading: true, data: null, error: null });
    if (userLoginInfo.email && userLoginInfo.password)
      axios
        .post(`${backend_url}/api/ise/v1/auth/signup`, {
          email: userLoginInfo.email,
          password: userLoginInfo.password,
          referralId,
        })
        .then((res) => {
          setSignUpRequest({
            data: res.data,
            error: null,
            isLoading: false,
          });
          navigate("/verify-email");
          localStorage.setItem("ise-user-email", userLoginInfo.email);
        })
        .catch((err) => {
          localStorage.setItem("ise-user-email", userLoginInfo.email);
          setSignUpRequest({
            data: null,
            error: err.response?.data?.error
              ? err.response?.data?.error?.responseMessage
              : !err.response?.data?.error
              ? err.response?.data?.responseMessage.toString()
              : err.message,
            isLoading: false,
          });
        });
  };

  const verifyEmail = (token: string) => {
    if (verifyEmailRequest.isLoading) {
      return;
    }

    currentSearchParams.set("isVerified", "false");
    setSearchParams(currentSearchParams.toString());
    setVerifyEmailRequest({ isLoading: true, data: null, error: null });
    axios
      .post(`${backend_url}/api/ise/v1/auth/student/verify`, {
        token,
      })
      .then((res) => {
        setVerifyEmailRequest({
          data: res.data,
          error: null,
          isLoading: false,
        });

        currentSearchParams.set("isVerified", "true");
        setSearchParams(currentSearchParams.toString());

        localStorage.setItem("iseAccessToken", res.data?.accessToken);
        localStorage.setItem("iseRefreshToken", res.data?.refreshToken);
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );
        localStorage.removeItem("ise-user-email");
        setVerifyEmailRequest({
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
          isLoading: false,
        });

        currentSearchParams.set("isVerified", "false");
        setSearchParams(currentSearchParams.toString());

        if (err?.response?.data?.responseMessage === "User already verified") {
          navigate("/onboarding?step=1");
        }
      });
  };

  const resendVerifyEmail = () => {
    if (verifyEmailRequest.isLoading) {
      return;
    }

    setVerifyEmailRequest({ isLoading: true, data: null, error: null });
    axios
      .post(
        `${backend_url}/api/ise/v1/auth/student/resend-verification/${iseUSerEmail}`,
        {
          email: iseUSerEmail,
        }
      )
      .then((res) => {
        setVerifyEmailRequest({
          data: res.data,
          error: null,
          isLoading: false,
        });
      })
      .catch((err) => {
        setVerifyEmailRequest({
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
          isLoading: false,
        });

        currentSearchParams.set("isVerified", "false");
        setSearchParams(currentSearchParams.toString());

        if (err?.response?.data?.responseMessage === "User already verified") {
          navigate("/onboarding?step=1");
        }
      });
  };

  const signIn = () => {
    setSignInRequest({ isLoading: true, data: null, error: null });
    if (userLoginInfo.email && userLoginInfo.password)
      axios
        .post(`${backend_url}/api/ise/v1/auth/students/login`, {
          email: userLoginInfo.email,
          password: userLoginInfo.password,
        })
        .then((res) => {
          setSignInRequest({
            data: res.data,
            error: null,
            isLoading: false,
          });

          setToken(TokenKeys.ACCESS_TOKEN, res.data.accessToken);
          setToken(TokenKeys.REFRESH_TOKEN, res.data.refreshToken);

          navigate(redirectRoute);

          getUser();
        })
        .catch((err) => {
          localStorage.setItem("ise-user-email", userLoginInfo.email);

          setSignInRequest({
            data: null,
            error: err.response?.data?.error
              ? err.response?.data?.error?.responseMessage
              : !err.response?.data?.error
              ? err.response?.data?.responseMessage.toString()
              : err.message,
            isLoading: false,
          });

          if (
            err?.response?.data?.responseMessage ===
            "Email Verification Required!"
          ) {
            navigate("/verify-email");
          }
        });
  };

  const [completeProfileData, setCompleteProfileData] =
    useState<completeUserProfile>({
      first_name: "",
      last_name: "",
      gender: "",
      country: "",
      bio: "",
      employement_status: "",
      education: "",
      linkedIn_profile: "",
      profile_image: "",
    });
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [employmentStatus, setEmploymentStatus] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [completeProfileRequest, setCompleteProfileRequest] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });
  const completeProfileFormData = new FormData();

  useEffect(() => {
    completeProfileFormData.append(
      "first_name",
      completeProfileData.first_name
    );
    completeProfileFormData.append("last_name", completeProfileData.last_name);
    completeProfileFormData.append("gender", completeProfileData.gender);
    completeProfileFormData.append("country", completeProfileData.country);
    completeProfileFormData.append("bio", completeProfileData.bio);
    completeProfileFormData.append(
      "employement_status",
      completeProfileData.employement_status
    );
    completeProfileFormData.append("education", completeProfileData.education);
    completeProfileFormData.append(
      "linkedIn_profile",
      completeProfileData.linkedIn_profile
    );
    completeProfileFormData.append(
      "profile_image",
      completeProfileData.profile_image
    );

    // eslint-disable-next-line
  }, [completeProfileData]);

  const completeProfile = () => {
    if (completeProfileRequest.isLoading) {
      return;
    }
    setCompleteProfileRequest({ isLoading: true, data: null, error: null });
    axios
      .post(
        `${backend_url}/api/ise/v1/students/complete-profile`,
        completeProfileFormData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setCompleteProfileRequest({
          isLoading: false,
          data: res.data,
          error: null,
        });

        currentSearchParams.set("step", "3");
        setSearchParams(currentSearchParams.toString());

        getUser(false);
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );
        setCompleteProfileRequest({
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
          isLoading: false,
        });
      });
  };

  const resetPassword = () => {
    setResetPasswordRequest({ isLoading: true, data: null, error: null });
    axios
      .post(`${backend_url}/api/ise/v1/auth/student/resetPasswordToken`, {
        email: userLoginInfo.email || iseUSerEmail,
      })
      .then((res) => {
        setResetPasswordRequest({
          data: res.data,
          error: null,
          isLoading: false,
        });

        navigate("/forgot-password/check-email");
      })
      .catch((err) => {
        setResetPasswordRequest({
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
          isLoading: false,
        });
      });
  };

  const [resetPasswordObject, setResetPasswordObject] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const verifyResetPassword = (token: string) => {
    setResetPasswordRequest({ isLoading: true, data: null, error: null });
    axios
      .post(`${backend_url}/api/ise/v1/auth/student/resetPassword`, {
        token: token,
        newPassword: resetPasswordObject.newPassword,
      })
      .then((res) => {
        setResetPasswordRequest({
          data: res.data,
          error: null,
          isLoading: false,
        });
      })
      .catch((err) => {
        setResetPasswordRequest({
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
          isLoading: false,
        });
      });
  };

  const [contactProgramAdvisorObject, setContactProgramAdvisorObject] =
    useState({
      name: "",
      email: "",
      message: "",
      advisor_email: "ezimorahtobenna@gmail.com",
    });
  const [contactProgramAdvisorRequest, setContactProgramAdvisorRequest] =
    useState<requestType>({ isLoading: false, data: null, error: null });

  const eligibilityContactProgramAdvisor = () => {
    setContactProgramAdvisorRequest({
      isLoading: true,
      data: null,
      error: null,
    });
    axios
      .post(
        `${backend_url}/api/ise/v1/course-eligibility/contact-advisor`,
        contactProgramAdvisorObject
      )
      .then((res) => {
        setContactProgramAdvisorRequest({
          data: res.data,
          error: null,
          isLoading: false,
        });

        // navigate("/forgot-password/check-email");
      })
      .catch((err) => {
        setContactProgramAdvisorRequest({
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
          isLoading: false,
        });
      });
  };

  const [eligibilityQuestions, setEligibilityQuestions] = useState([]);
  const [getEligibilityQuestionsrequest, setGetElibilityQuestionsRequest] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });

  const getEligibilityQuestions = (id: string) => {
    if (getEligibilityQuestionsrequest.isLoading) {
      return;
    }
    setGetElibilityQuestionsRequest({
      isLoading: true,
      data: null,
      error: null,
    });
    axios
      .get(`${backend_url}/api/ise/v1/course-eligibility/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setGetElibilityQuestionsRequest({
          data: res.data,
          error: null,
          isLoading: false,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setGetElibilityQuestionsRequest({
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
          isLoading: false,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const getCoursesById = (id: string) => {
    if (iseCoursesDetail.isLoading) {
      return;
    }
    if (id) {
      setIseCoursesDetail({ isLoading: true, data: null, error: null });

      requestHandler({
        method: "GET",
        url: `${backend_url}/api/ise/v1/courses/${id}`,
      })
        .then((res) => {
          setIseCoursesDetail({
            isLoading: false,
            data: (res as any).data,
            error: null,
          });
        })
        .catch((err) => {
          setIseCoursesDetail({
            isLoading: false,
            data: null,
            error: err.response?.data?.error
              ? err.response?.data?.error?.responseMessage
              : !err.response?.data?.error
              ? err.response?.data?.responseMessage.toString()
              : err.message,
          });

          setNotiticationFunction(
            setNotifications,
            err.response?.data?.error
              ? err.response?.data?.error?.responseMessage
              : !err.response?.data?.error
              ? err.response?.data?.responseMessage.toString()
              : err.message
          );

          if (err?.response?.data?.error?.responseMessage === "Expired Token") {
            navigate("/sign-in", { state: location.pathname });
          }
        });
    }
  };

  const [eligibleAnswers, setEligibleAnswers] = useState({
    eligibility_payload: [],
  });

  const [
    gradeEligibilityQuestionsRequest,
    setGradeEligibilityQuestionsRequest,
  ] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  const gradeEligibilityQuestions = (id: string) => {
    if (id && eligibleAnswers.eligibility_payload?.length > 0) {
      getCoursesById(id);

      setGradeEligibilityQuestionsRequest({
        isLoading: true,
        data: null,
        error: null,
      });

      requestHandler({
        method: "POST",
        url: `${backend_url}/api/ise/v1/course-eligibility/grade-answers/${id}`,
        data: eligibleAnswers,
      })
        .then((res: any) => {
          setGradeEligibilityQuestionsRequest({
            isLoading: false,
            data: null,
            error: null,
          });
          if (res.data?.scorePercentage < 50) {
            currentSearchParams.set("status", "fail");
            setSearchParams(currentSearchParams.toString());
          }
          if (res.data?.scorePercentage >= 50) {
            currentSearchParams.set("status", "pass");
            setSearchParams(currentSearchParams.toString());
          }
        })
        .catch((err) => {
          setNotiticationFunction(
            setNotifications,
            err.response?.data?.error
              ? err.response?.data?.error?.responseMessage
              : !err.response?.data?.error
              ? err.response?.data?.responseMessage.toString()
              : err.message
          );

          setGradeEligibilityQuestionsRequest({
            isLoading: false,
            data: null,
            error: err.response?.data?.error
              ? err.response?.data?.error?.responseMessage
              : !err.response?.data?.error
              ? err.response?.data?.responseMessage.toString()
              : err.message,
          });

          if (err.response?.data?.error?.responseMessage === "Expired Token") {
            logout();
          }
        });
    }
  };

  const [passwordUpdate, setPasswordUpdate] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [emailUpdate, setEmailUpdate] = useState({
    newEmail: "",
  });
  const [skillsUpdate, setSkillsUpdate] = useState<{
    top_skills: string[];
    employement_status: string;
    education: string;
    experience_level: string;
  }>({
    top_skills: [],
    employement_status: "",
    education: "",
    experience_level: "",
  });

  const [aboutInfoUpdate, setAboutInfoUpdate] = useState({
    full_name: "",
    gender: "",
    country: "",
    preferred_language: "",
  });

  const [topSkillsUpdate, setTopSkillsUpdate] = useState({
    dob: "",
    bio: "",
    phone: "",
    linkedIn_profile: "",
  });

  const aboutInfoFormData = new FormData();

  const [updatePasswordHandlerObject, setUpdatePasswordHandlerObject] =
    useState<requestType>({
      isLoading: false,
    });

  const [updateEmailHandlerObject, setUpdateEmailHandlerObject] =
    useState<requestType>({
      isLoading: false,
      data: null,
    });

  const [updateAboutHandlerObject, setUpdateAboutHandlerObject] =
    useState<requestType>({
      isLoading: false,
    });

  const [updateSkillsHandlerObject, setUpdateSkillsHandlerObject] =
    useState<requestType>({
      isLoading: false,
    });

  const [updateTopSkillsHandlerObject, setUpdateTopSkillsHandlerObject] =
    useState<requestType>({
      isLoading: false,
    });

  const [userBadgesObject, setUserBadgesObject] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  const updatePasswordHandler = () => {
    setUpdatePasswordHandlerObject({
      isLoading: true,
    });
    requestHandler({
      method: "POST",
      url: `${backend_url}/api/ise/v1/students/password/change`,
      data: passwordUpdate,
    })
      .then((res) => {
        setNotiticationFunction(
          setNotifications,
          capitalize((res as AxiosResponse).data as string) || "",
          "success"
        );

        setUpdatePasswordHandlerObject({
          isLoading: false,
        });

        setPasswordUpdate({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      })
      .catch((err) => {
        setUpdatePasswordHandlerObject({
          isLoading: false,
        });
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        if (err.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const updateEmailHandler = () => {
    setUpdateEmailHandlerObject({
      isLoading: true,
      data: null,
    });
    requestHandler({
      method: "POST",
      url: `${backend_url}/api/ise/v1/students/update-email`,
      data: { email: emailUpdate.newEmail },
    })
      .then((res) => {
        setUpdateEmailHandlerObject({
          isLoading: false,
          data: (res as any)?.data,
        });

        setNotiticationFunction(
          setNotifications,
          capitalize((res as AxiosResponse).data as string) || "",
          "success"
        );
      })
      .catch((err) => {
        setUpdateEmailHandlerObject({
          isLoading: false,
          data: null,
        });
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        if (err.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const updateAboutHandler = () => {
    setUpdateAboutHandlerObject({
      isLoading: true,
    });

    requestHandler({
      method: "PATCH",
      url: `${backend_url}/api/ise/v1/students/profile/about-info`,
      data: aboutInfoFormData,
      isMultipart: true,
    })
      .then((res) => {
        setUpdateAboutHandlerObject({
          isLoading: false,
        });

        setNotiticationFunction(
          setNotifications,
          capitalize((res as AxiosResponse).data as string) || "",
          "success"
        );
      })
      .catch((err) => {
        setUpdateAboutHandlerObject({
          isLoading: false,
        });

        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        if (err.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const updateSkillsHandler = () => {
    setUpdateSkillsHandlerObject({
      isLoading: true,
    });

    requestHandler({
      method: "PATCH",
      url: `${backend_url}/api/ise/v1/students/profile/skills`,
      data: skillsUpdate,
    })
      .then((res) => {
        setUpdateSkillsHandlerObject({
          isLoading: false,
        });

        setNotiticationFunction(
          setNotifications,
          capitalize((res as AxiosResponse).data as string) || "",
          "success"
        );
      })
      .catch((err) => {
        setUpdateSkillsHandlerObject({
          isLoading: false,
        });

        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        if (err.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const updateSTopkillsHandler = () => {
    setUpdateTopSkillsHandlerObject({
      isLoading: true,
    });

    requestHandler({
      method: "PATCH",
      url: `${backend_url}/api/ise/v1/students/profile/additional-info`,
      data: topSkillsUpdate,
    })
      .then((res) => {
        setUpdateTopSkillsHandlerObject({
          isLoading: false,
        });

        setNotiticationFunction(
          setNotifications,
          capitalize((res as AxiosResponse).data as string) || "",
          "success"
        );
      })
      .catch((err) => {
        setUpdateTopSkillsHandlerObject({
          isLoading: false,
        });

        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        if (err.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const getUserBadges = () => {
    if (userBadgesObject.isLoading) {
      return;
    }

    setUserBadgesObject({
      isLoading: true,
    });

    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/students/badges`,
    })
      .then((res) => {
        setUserBadgesObject({
          isLoading: false,
          data: (res as AxiosResponse).data as string,
          error: null,
        });
      })
      .catch((err) => {
        setUserBadgesObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        if (err.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const [closeAccountObject, setCloseAccountObject] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  const closeAccount = () => {
    if (closeAccountObject.isLoading) {
      return;
    }

    setCloseAccountObject({
      isLoading: true,
    });

    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/students/badges`,
    })
      .then((res) => {
        setCloseAccountObject({
          isLoading: false,
          data: (res as AxiosResponse).data as string,
          error: null,
        });
      })
      .catch((err) => {
        setCloseAccountObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        if (err.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const [badges, setBadges] = useState([
    {
      title: "Verified Profile",
      description: "Your iṣẹ́ EdTech profile is complete",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M9.49963 12.0006L11.4996 14.0006L15.4996 10.0006M8.33437 4.69766C9.05191 4.6404 9.73309 4.35824 10.281 3.89136C11.5594 2.80184 13.4398 2.80184 14.7183 3.89136C15.2662 4.35824 15.9474 4.6404 16.6649 4.69766C18.3393 4.83128 19.669 6.16092 19.8026 7.83535C19.8598 8.55288 20.142 9.23407 20.6089 9.78193C21.6984 11.0604 21.6984 12.9408 20.6089 14.2193C20.142 14.7672 19.8598 15.4483 19.8026 16.1659C19.669 17.8403 18.3393 19.1699 16.6649 19.3036C15.9474 19.3608 15.2662 19.643 14.7183 20.1099C13.4398 21.1994 11.5594 21.1994 10.281 20.1099C9.73309 19.643 9.05191 19.3608 8.33437 19.3036C6.65994 19.1699 5.3303 17.8403 5.19668 16.1659C5.13942 15.4483 4.85727 14.7672 4.39038 14.2193C3.30086 12.9408 3.30086 11.0604 4.39038 9.78193C4.85727 9.23407 5.13942 8.55288 5.19668 7.83535C5.3303 6.16092 6.65994 4.83128 8.33437 4.69766Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      earned: true,
    },
    {
      title: "Newbie",
      description: "You joined iṣẹ́ as a leaner",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <g clipPath="url(#clip0_9134_17525)">
            <path
              d="M11.549 2.92664C11.8483 2.00537 13.1517 2.00538 13.451 2.92664L14.9699 7.60055C15.1038 8.01254 15.4877 8.29148 15.9209 8.29149L20.8354 8.29168C21.8041 8.29172 22.2068 9.53127 21.4232 10.1007L17.4474 12.9895C17.0969 13.2441 16.9503 13.6955 17.0841 14.1075L18.6026 18.7815C18.9019 19.7028 17.8475 20.4689 17.0638 19.8995L13.0878 17.011C12.7373 16.7564 12.2627 16.7564 11.9122 17.011L7.93622 19.8995C7.15252 20.4689 6.0981 19.7028 6.3974 18.7815L7.91589 14.1075C8.04974 13.6955 7.90309 13.2441 7.55263 12.9895L3.57683 10.1007C2.79317 9.53127 3.19592 8.29172 4.16461 8.29168L9.07911 8.29149C9.51231 8.29148 9.89623 8.01254 10.0301 7.60055L11.549 2.92664Z"
              stroke="white"
              strokeWidth="2"
            />
          </g>
          <defs>
            <clipPath id="clip0_9134_17525">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      earned: false,
    },
    {
      title: "Weekend Warrior",
      description:
        "Complete a lesson on Saturday and Sunday to earn this badge. ",
      svg: null,
      earned: false,
    },
    {
      title: "Trailblazer",
      description: "Refer  2 friends successfully to earn this badge. ",
      svg: null,
      earned: false,
    },

    {
      title: "Community Champion",
      description: "Actively support your peers to earn this badge. ",
      svg: null,
      earned: false,
    },
    {
      title: "Completion Master",
      description: "Complete all iṣẹ́ EdTech assessments to earn this badge. ",
      svg: null,
      earned: false,
    },
    {
      title: "Proactive Learner",
      description: "Seek support from your tutor to earn this badge.",
      svg: null,
      earned: false,
    },
    {
      title: "Leaner of the month",
      description: "Score high on your assessments to earn this badge.",
      svg: null,
      earned: false,
    },
    {
      title: "Photogenic",
      description: "Add a profile picture to earn this badge. ",
      svg: null,
      earned: false,
    },
  ]);

  useEffect(() => {
    if (userBadgesObject?.data?.length > 0) {
      const smallerArrayBadges = new Set(
        userBadgesObject?.data?.map((data: any) => data.badge)
      );

      const badgesCopy = badges?.map((data) => {
        return {
          ...data,
          earned: smallerArrayBadges?.has(data?.title.toLowerCase()),
        };
      });

      setBadges(badgesCopy as any[]);
    }

    // eslint-disable-next-line
  }, [userBadgesObject?.data]);

  return (
    <AuthUserContext.Provider
      value={{
        userLoginInfo,
        setUserLoginInfo,
        searchParams,
        setSearchParams,
        fetchCountries,
        countriesRequestObject,
        setCountriesRequestObject,
        signUp,
        signupRequest,
        setSignUpRequest,
        verifyEmail,
        verifyEmailRequest,
        setVerifyEmailRequest,
        signIn,
        signInRequest,
        setSignInRequest,
        logout,
        resetPassword,
        resetPasswordRequest,
        completeProfileData,
        setCompleteProfileData,
        completeProfile,
        gender,
        setGender,
        country,
        setCountry,
        employmentStatus,
        setEmploymentStatus,
        educationLevel,
        setEducationLevel,
        completeProfileRequest,
        setCompleteProfileRequest,
        resendVerifyEmail,
        resetPasswordObject,
        setResetPasswordObject,
        verifyResetPassword,
        setResetPasswordRequest,
        contactProgramAdvisorObject,
        setContactProgramAdvisorObject,
        contactProgramAdvisorRequest,
        setContactProgramAdvisorRequest,
        eligibilityContactProgramAdvisor,
        getEligibilityQuestions,
        eligibilityQuestions,
        setEligibilityQuestions,
        getEligibilityQuestionsrequest,
        setGetElibilityQuestionsRequest,
        userToken,
        eligibleAnswers,
        setEligibleAnswers,
        gradeEligibilityQuestions,
        gradeEligibilityQuestionsRequest,
        setGradeEligibilityQuestionsRequest,
        getCoursesById,
        iseCoursesDetail,
        user,
        iseUserFirstName,
        passwordUpdate,
        setPasswordUpdate,
        updatePasswordHandlerObject,
        updatePasswordHandler,
        emailUpdate,
        setEmailUpdate,
        updateEmailHandler,
        updateEmailHandlerObject,
        updateSkillsHandler,
        updateSkillsHandlerObject,
        skillsUpdate,
        setSkillsUpdate,
        getUser,
        updateAboutHandler,
        updateAboutHandlerObject,
        aboutInfoUpdate,
        setAboutInfoUpdate,
        aboutInfoFormData,
        updateTopSkillsHandlerObject,
        updateSTopkillsHandler,
        topSkillsUpdate,
        setTopSkillsUpdate,
        userBadgesObject,
        getUserBadges,
        closeAccountObject,
        closeAccount,
        setCloseAccountObject,
        badges,
        setBadges,
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};

export const useAuth = () => useContext(AuthUserContext);

export default AuthUserContextProvider;
